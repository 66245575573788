import Chapters from "../pages/chapters";
import ViewChapter from "../pages/chapters/view_chapter";
import Contacts from "../pages/contacts";
import MessageReport from "../pages/reports/message_log";
import CreateChapter from "../pages/create_chapter";
import CreateTemplate from "../pages/create_template";
import SendMessage from "../pages/send_message";
import Groups from "../pages/groups";
import MessageTemplates from "../pages/message_templates";

export const AuthenticationRoutes = [
    {
        path: '/',
        component: <SendMessage />
    },
    {
        path: '/groups',
        component: <Groups />
    },
    {
        path: '/contacts',
        component: <Contacts />
    },
    {
        path: '/fav-groups',
        component: <Chapters />
    },
    {
        path: '/message-templates',
        component: <MessageTemplates />
    },
    {
        path: '/create-fav-group',
        component: <CreateChapter />
    },
    {
        path: '/fav-group/:id',
        component: <ViewChapter />
    },
    {
        path: '/create-template',
        component: <CreateTemplate />
    },
    {
        path: '/message-templates/:id',
        component: <CreateTemplate />
    },
    {
        path: '/report/message',
        component: <MessageReport />
    },
]