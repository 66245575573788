export const menuList = [
    {
        "id": "1",
        "menu_name": "Send Message",
        "path": "/",
        "icon": "i-mingcute-message-4-line"
    },
    {
        "id": "2",
        "menu_name": "Groups",
        "path": "/groups",
        "icon": "i-material-symbols-groups-outline-rounded"
    },
    {
        "id": "3",
        "menu_name": "Contacts",
        "path": "/contacts",
        "icon": "i-system-uicons-contacts"
    },
    {
        "id": "4",
        "menu_name": "Fav Groups",
        "path": "/fav-groups",
        "icon": "i-solar-book-outline"
    },
    {
        "id": "5",
        "menu_name": "Messages Templates",
        "path": "/message-templates",
        "icon": "i-solar-gallery-outline"
    },
    {
        "id": "6",
        "menu_name": "Message Logs",
        "path": "/report/message",
        "icon": "i-solar-gallery-outline"
    }
]