  export const baseUrl = "https://api.bnicbehub.com";
 //export const baseUrl = "http://127.0.0.1:8000";

export const loginEndPoint = baseUrl + "/accounts/login/";
export const logoutEndPoint = baseUrl + "/accounts/logout/";

export const groupListEndPoint = baseUrl + "/group-integrations/group-list/";
export const contactListEndPoint = baseUrl + "/group-integrations/contact-list/";
export const syncDataEndPoint = baseUrl + "/group-integrations/sycn-data/";

export const chapterListEndPoint = baseUrl + "/group-integrations/chapter-list/";
export const chapterEndPoint = baseUrl + "/group-integrations/chapter/";

export const templatesListEndPoint = baseUrl + "/group-integrations/templates-list/";
export const templatesEndPoint = baseUrl + "/group-integrations/templates/";

export const getColumnNamesEndPoint = baseUrl + "/group-integrations/excel-file-column-names/";
export const extractColumnsValuesEndPoint = baseUrl + "/group-integrations/excel-file-data/";
export const excelSheetNamesValuesEndPoint = baseUrl + "/group-integrations/excel-file-sheet-names/";
export const excelSheetChapterValuesEndPoint = baseUrl + "/group-integrations/excel-file-sheet-chapter/";

export const sendMessageEndPoint = baseUrl + "/group-integrations/whatsapp-message/";
export const sendImageMessageEndPoint = baseUrl + "/group-integrations/whatsapp-image-message/";

export const messageLogListEndPoint = baseUrl + "/group-integrations/messagelog-list/";
export const messageResendEndPoint = baseUrl + "/group-integrations/resend-message/";

export const profileInfoEndPoint = baseUrl + "/group-integrations/whatsapp-profile/";
