import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { setIsLoading } from "../../redux/layout_slice";
import { excelSheetChapterValuesEndPoint, excelSheetNamesValuesEndPoint, extractColumnsValuesEndPoint, getColumnNamesEndPoint, sendImageMessageEndPoint, sendMessageEndPoint, templatesListEndPoint } from "../../service/api_endpoints";
import { getAxiosWithToken, postAxiosFormWithToken, postAxiosWithToken } from "../../service/axios_service";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { useFormik } from "formik";
import ChapterList from "./chapter_list";
import toast from "react-hot-toast";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { exportToExcel } from 'react-json-to-excel';

export default function SendMessage() {

  const dispatch = useDispatch();

  const [templateList, setTemplateList] = useState([]);

  const [columnNameList, setColumnNameList] = useState([]);
  const [selectedColumnNameList, setSelectedColumnNameList] = useState([]);

  const [selectedTempalte, setSelectedTemplate] = useState(null);
  const [selectedTempalteName, setSelectedTemplateName] = useState(null);
  const [selectedChapterList, setSelectedChapterList] = useState([]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [leaveColumnEnabled, setLeaveColumnEnabled] = useState(false);
  const [sortColumnEnabled, setSortColumnEnabled] = useState(false);

  const [selectedSortColumn, setSelectedSortColumn] = useState("")
  const [selectedLeaveColumn, setSelectedLeaveColumn] = useState("")
  const [selectedValidatedColumn, setSelectedValidatedColumn] = useState(null)
  
  const [selectedSheetName, setSelectedSheetName] = useState(null)
  const [sheetNameList, setSheetNameList] = useState([])

  const [innerText, setInnerText] = useState();

  const [firstSubmit, setFirstSubmit] = useState(false);
  const [overviewData, setOverviewData] = useState([]);
  const [overviewDataColumn, setOverviewDataColumn] = useState([]);

  const [messageResponse, setMessageRespone] = useState([]);
  const [responsePopUp, setResponsePopUp] = useState(false);

  const [hasMultiSheet, setHasMultiSheet] = useState(false);
  const [multiSheetDataList, setMultiSheetDataList] = useState([]);

  const [hasLink, setHasLink] = useState(false);
  const [selectedLinkColumnIndex, setSelectedLinkColumnIndex] = useState(null)

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote',], //'code-block'
    // ['link', 'image', 'video', 'formula'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    [{ 'align': [] }],

    // ['clean']                                         // remove formatting button
  ];

  useEffect(() => {
    getTemplates();
  }, [])

  const getTemplates = async () => {
    dispatch(setIsLoading(true))
    let response = await getAxiosWithToken({
      url: templatesListEndPoint,
    })

    if (response != null) {
      setTemplateList(response.data.list)
      dispatch(setIsLoading(false))
    }
  }


  const showColumns = async (selectedIndex) => {

    var formData = new FormData(document.getElementById('messageform'));
    formData.append("selected_sheet", selectedIndex)
    let response = await postAxiosFormWithToken({
      url: getColumnNamesEndPoint,
      body: formData
    })

    if (response != null) {
      setColumnNameList(response.data?.column_list)
    }

  }

  const showSheetNames = async () => {

    var formData = new FormData(document.getElementById('messageform'));

    let response = await postAxiosFormWithToken({
      url: excelSheetNamesValuesEndPoint,
      body: formData
    })

    if (response != null) {
      setSheetNameList(response.data?.sheet_name_list)
    }

  }

  const formik = useFormik({
    initialValues: {
      template: '',
      excel_file: '',
      leave_row_value: ''
    },
    validationSchema: Yup.object({
      template: Yup.string()
        .required('Template name is required'),
      excel_file: (selectedTempalte?.has_excel && selectedTempalte == null) ? Yup.mixed().required('File is required')
        .test('fileFormat', 'xlsx, xls, csv file only allowed', value => {
          if (value) {
            const supportedFormats = ['xlsx', 'xls', 'csv'];
            return supportedFormats.includes(value.split('.')[value.split('.').length - 1]);
          }
          return true;
        }) : Yup.mixed(),
      leave_row_value: (selectedTempalte?.leave_row && selectedTempalte != null) ? Yup.string()
        .required('Leave row is required') : Yup.string()
    }),
    onSubmit: async (values) => {
      setSubmitLoading(true);

      if (selectedChapterList.length > 0) {

        let chapterList = []

        for (var i of selectedChapterList) {
          chapterList.push(i.id)
        }

        let formData = new FormData(document.getElementById('messageform'));
        if (selectedTempalte?.has_excel) {
          formData.append('leave_row', leaveColumnEnabled)
          formData.append('leave_row_value', values.leave_row_value)
          formData.append('leave_row_column_name', selectedLeaveColumn)
          formData.append("selected_sheet", selectedSheetName)
          formData.append('excel_index', selectedValidatedColumn)
          formData.append('sort_column', selectedSortColumn)
          formData.append('link_column_index', selectedLinkColumnIndex)
          formData.append('add_link', hasLink)
          formData.append('sort', (selectedTempalte != null && selectedTempalte?.is_sortable) ? selectedTempalte?.is_sortable : false)
          formData.append('column_names', selectedColumnNameList.join(','));
          formData.append('excel_image', (selectedTempalte != null && selectedTempalte?.excel_image) ? selectedTempalte?.excel_image : false);
        }
        formData.append('has_excel', (selectedTempalte != null && selectedTempalte?.has_excel) ? selectedTempalte?.has_excel : false);
        formData.append('message', document.getElementById('preview').innerText)
        formData.append('template_id', selectedTempalte.id)
        formData.append('template_name', selectedTempalteName)
        formData.append('fav_group', chapterList.join(','))

        if (selectedTempalte != null && selectedTempalte?.excel_image && hasMultiSheet) {
          let response = await postAxiosFormWithToken({
            url: excelSheetChapterValuesEndPoint,
            body: formData
          })

          if (response != null) {
            
            setFirstSubmit(true)
            setMultiSheetDataList(response.data)
          }
        } else if (selectedTempalte != null && selectedTempalte?.excel_image) {
          let response = await postAxiosFormWithToken({
            url: extractColumnsValuesEndPoint,
            body: formData
          })

          if (response != null) {
            setOverviewData(response.data.data_list)
            setFirstSubmit(true)
            setOverviewDataColumn(response.data.column_list)
          }
          
        } else {
          let response = await postAxiosFormWithToken({
            url: sendMessageEndPoint,
            body: formData
          })

          if (response != null) {
            setResponsePopUp(true);
            setMessageRespone(response.data)
            formik.resetForm()
            setColumnNameList([])
            setSelectedChapterList([])
            setSelectedSortColumn("")
            setInnerText("")
            setSendMessageLoading(false)
            setOverviewData([])
            setFirstSubmit(false)
            setOverviewDataColumn([])
          } else {
            setSendMessageLoading(false)
            toast.error('Message sending incomplete')
          }
        }

      } else {
        toast.error('Select atleast one fav group')
      }

      setSubmitLoading(false);
    },

  });


  const sentImageMessage = async () => {
    setSendMessageLoading(true)

    let messageList = []
    for (var i of overviewData) {
      var element = document.getElementById(i.unique_id)

      let messageData = {
        "group_name": i.group_name,
        "chapter": i.chapter_name,
        "to": i.group_id,
        "message": i.message
      }
      await htmlToImage.toJpeg(element)
        .then(function (dataUrl) {
          messageData['image'] = dataUrl
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });

      messageList.push(messageData)

    }

    let response = await postAxiosWithToken({
      url: sendImageMessageEndPoint,
      body: {
        "message_list": messageList
      }
    })

    if (response != null) {
      // toast.success(response.message)
      setResponsePopUp(true);
      setMessageRespone(response.data)
      setSendMessageLoading(false)
      formik.resetForm()
      setColumnNameList([])
      setSelectedChapterList([])
      setSelectedSortColumn("")
      setInnerText("")
      setOverviewData([])
      setFirstSubmit(false)
      setOverviewDataColumn([])
    }
  }

  const multiSheetMessage = async () => {
    setSendMessageLoading(true)

    let messageList = []

    for (var i of multiSheetDataList) {
      var element = document.getElementById(i.unique_id)

      let messageData = {
        "group_name": i.group_name,
        "chapter": i.chapter_name,
        "to": i.group_id,
        "message": i.message
      }
      await htmlToImage.toJpeg(element)
        .then(function (dataUrl) {
          messageData['image'] = dataUrl
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });

      messageList.push(messageData)

    }

    let response = await postAxiosWithToken({
      url: sendImageMessageEndPoint,
      body: {
        "message_list": messageList
      }
    })

    if (response != null) {
      // toast.success(response.message)
      setResponsePopUp(true);
      setMessageRespone(response.data)
      setSendMessageLoading(false)
      formik.resetForm()
      setColumnNameList([])
      setSelectedChapterList([])
      setSelectedSortColumn("")
      setInnerText("")
      setOverviewData([])
      setFirstSubmit(false)
      setOverviewDataColumn([])
      setMultiSheetDataList([])
      setHasMultiSheet(false)
    }
  }

  return (
    <div>
      {firstSubmit && <button className="text-primary" onClick={(e) => { e.preventDefault(); setFirstSubmit(!firstSubmit); formik.setFieldValue('excel_file', "") }}>Go back</button>}
      {
        firstSubmit ? !hasMultiSheet ? <div className="bg-white">
          {
            overviewData.map((item) => (
              <div className="my-[30px]">
                <p className="font-bold text-xl">{item.chapter_name}</p>
                <p className="text-primary">{item.group_name}</p>
                <div className="w-full overflow-scroll">
                  <table id={item.unique_id} className="w-full">
                    <thead className="bg-light-gray text-white">
                      <tr>
                        <th className="p-[10px] text-left">S.No</th>
                        {
                          overviewDataColumn.map((col) => (
                            <th className="p-[10px] text-left text-[30px]">{col}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        item.sheet_data?.map((sheet, sheetIndex) => (
                          <tr className={`${sheetIndex % 2 != 0 ? "bg-input-gray" : "bg-white"} border-b`}>
                            <td className="p-[10px] text-left">{sheetIndex + 1}</td>
                            {
                              Object.values(sheet).map((val) => (
                                <td className="p-[10px] text-left text-[30px]">{val}</td>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>

                </div>
              </div>
            ))
          }
          <button onClick={(e) => { e.preventDefault(); sentImageMessage() }} type="button" disabled={sendMessageLoading} className='w-full my-[50px] bg-primary px-[15px] py-[10px] text-white rounded'>{sendMessageLoading ? "Loading..." : "Send Message"}</button>
        </div> : <div className="bg-white">
          {
            multiSheetDataList.map((item) => (
              <div className="my-[30px]">
                <p className="font-bold text-xl">{item.chapter_name}</p>
                <div className="w-full overflow-scroll">
                  <table id={item.unique_id} className="w-full">
                    <thead className="bg-light-gray text-white">
                      <tr>
                        <th className="p-[10px] text-left">Chapter Name</th>
                        <th className="p-[10px] text-left">Name</th>
                        <th className="p-[10px] text-left">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        item?.sheet_data?.map((subItem, sheetIndex) => (
                          <tr className={`${sheetIndex % 2 != 0 ? "bg-input-gray" : "bg-white"} border-b`}>
                            <td className="p-[10px] text-left text-[30px]">{item.chapter_name}</td>
                            <td className="p-[10px] text-left text-[30px]">{subItem.name}</td>
                            <td className="p-[10px] text-left text-[30px]">{subItem.message}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          }
          <button onClick={(e) => { e.preventDefault(); multiSheetMessage() }} type="button" disabled={sendMessageLoading} className='w-full my-[50px] bg-primary px-[15px] py-[10px] text-white rounded'>{sendMessageLoading ? "Loading..." : "Send Message"}</button>
        </div> :
          <div>
            <div className="flex items-start justify-between gap-3">
              <form onSubmit={formik.handleSubmit} id="messageform">
                <div className="mb-[25px]">
                  <p>Select Template</p>
                  <select name="template" id="template" value={formik.values.template} onChange={(e) => {
                    for (var i of templateList) {
                      if (i.id.toString() == e.target.value) {
                        setSelectedTemplate(i)
                        setSelectedTemplateName(i.template_name)
                        setInnerText(i.content_html)
                        setLeaveColumnEnabled(i.leave_row)
                        setSortColumnEnabled(i.is_sortable)
                        setHasMultiSheet(i.has_multi_sheet)
                        setHasLink(i.has_link)
                      }
                    }
                    setSelectedColumnNameList([]);
                    setColumnNameList([]);
                    formik.resetForm()
                    formik.handleChange(e)
                  }} className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]'>
                    <option value={""}>-- choose template --</option>
                    {
                      templateList.map((item, index) => (
                        <option value={item.id}>{item.template_name}</option>
                      ))
                    }
                  </select>
                  {formik.errors.template && <p className='text-red-500 text-xs'>{formik.errors.template}</p>}
                </div>
                <div className='mb-[25px]'>
                  <p>Message</p>
                  <ReactQuill theme='snow' value={innerText} onChange={(value) => { setInnerText(value) }} modules={{
                    toolbar: toolbarOptions
                  }} />

                </div>
                {selectedTempalte?.has_excel && <div>
                  <p>Attach Excel</p>
                  <input type="file" id="excel_file" name="excel_file" onChange={(e) => {
                    const supportedFormats = ['xlsx', 'xls', 'csv'];
                    if (supportedFormats.includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
                      showSheetNames()
                    }
                    formik.handleChange(e)
                  }} value={formik.values.excel_file} className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]' />
                  {formik.errors.excel_file && <p className='text-red-500 text-xs'>{formik.errors.excel_file}</p>}
                </div>}

                {
                  (!hasMultiSheet && sheetNameList.length > 0) && <div>
                    <p className=" mt-[25px]">Select Sheet Name</p>
                    <div className="flex w-fit items-center border">
                      {sheetNameList.map((item, index) => (
                        <p onClick={() => {
                          showColumns(index)
                          setSelectedSheetName(index)
                        }} className={`${selectedSheetName == index ? "bg-light-gray text-white font-bold" : ""} cursor-pointer text-left w-fit p-[5px]`} >{item}</p>
                      ))
                      }</div></div>}
                
                {
                  (columnNameList.length > 0) && <div>
                    <p className=" mt-[25px]">Select Validation Column</p>
                    <div className="flex w-fit p-[5px] items-center border">
                      {columnNameList.map((item, index) => (
                        <p onClick={() => {
                          
                          setSelectedValidatedColumn(index)
                        }} className={`${selectedValidatedColumn == index ? "bg-light-gray text-white font-bold" : ""} cursor-pointer text-left w-fit p-[5px]`} >{item}</p>
                      ))
                      }</div></div>}

                {
                  (columnNameList.length > 0) && <div>
                    <p className=" mt-[25px]">Select Column Names</p>
                    <div className="flex w-fit items-center border">
                      {columnNameList.map((item) => (
                        <p onClick={() => {
                          if (selectedColumnNameList.includes(item)) {
                            let data = [...selectedColumnNameList];
                            var index = data.findIndex((column) => column == item);
                            data.splice(index, 1)
                            setSelectedColumnNameList(data)
                          } else {
                            let data = [...selectedColumnNameList];
                            data.push(item)
                            setSelectedColumnNameList(data)
                          }
                        }} className={`${selectedColumnNameList.includes(item) ? "bg-light-gray text-white font-bold" : ""} cursor-pointer text-left w-fit p-[5px]`} >{item}</p>
                      ))
                      }</div></div>}

                {
                  ((columnNameList.length > 0) && hasLink) && <div>
                    <p className=" mt-[25px]">Select Link Column</p>
                    <div className="flex w-fit p-[5px] items-center border">
                      {columnNameList.map((item, index) => (
                        <p onClick={() => {
                          setSelectedLinkColumnIndex(index)
                        }} className={`${selectedLinkColumnIndex == index ? "bg-light-gray text-white font-bold" : ""} cursor-pointer text-left w-fit p-[5px]`} >{item}</p>
                      ))
                      }</div></div>}

                {
                  (sortColumnEnabled && columnNameList.length > 0) && <div>
                    <p className=" mt-[25px]">Select Sort By</p>
                    <div className="flex w-fit items-center border">
                      {
                        columnNameList.map((item) => (
                          <p onClick={() => {
                            setSelectedSortColumn(item)
                          }} className={`${selectedSortColumn == item ? "bg-light-gray text-white font-bold" : ""} cursor-pointer text-left w-fit p-[5px]`} >{item}</p>
                        ))
                      }
                    </div>
                  </div>
                }

                {
                  (leaveColumnEnabled && columnNameList.length > 0) && <div>
                    <p className=" mt-[25px]">Leave Column</p>
                    <div className="flex w-fit items-center border">
                      {
                        columnNameList.map((item) => (
                          <p onClick={() => {
                            setSelectedLeaveColumn(item)
                          }} className={`${selectedLeaveColumn == item ? "bg-light-gray text-white font-bold" : ""} cursor-pointer text-left w-fit p-[5px]`} >{item}</p>
                        ))
                      }
                    </div>
                  </div>
                }

                {(leaveColumnEnabled && columnNameList.length > 0) && <div className='my-[10px]'>
                  <p>Leave Column Value</p>
                  <input type='text' name='leave_row_value' id='leave_row_value' placeholder="eg: 0,1" className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]' onChange={formik.handleChange} value={formik.values.template_name} />
                  {formik.errors.leave_row_value && <p className='text-red-500 text-xs'>{formik.errors.leave_row_value}</p>}
                </div>}

                <button type="submit" id="firstsubmit" className='hidden w-full my-[50px] bg-primary px-[15px] py-[10px] text-white rounded'>{submitLoading ? "Loading..." : "Submit"}</button>
              </form>

              {innerText?.length > 0 && <div>
                <p className='my-[10px] font-bold text-primary'>Template Preview</p>
                <div id="preview" dangerouslySetInnerHTML={{ __html: innerText }} className='w-full break-all text-sm border rounded shadow p-[10px]'></div>
              </div>}
            </div>

            <div>
            </div>
            <p className="mt-[25px] font-bold text-xl">Fav Group List</p>
            <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 sm:grid-cols-1  gap-3">
              <div className="w-full">
                <ChapterList setSelectedChapterList={setSelectedChapterList} selectedChapterList={selectedChapterList} />
              </div>

              <div className="w-full mt-[60px]  overflow-scroll">
                <table className="w-full">
                  <thead className="bg-light-gray text-white">
                    <tr>
                      <th className="p-[10px] text-left">S No</th>
                      <th className="p-[10px] text-left">Chapter Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      selectedChapterList.map((item, index) => (
                        <tr onClick={() => {
                          let data = [...selectedChapterList]
                          data.splice(index, 1)
                          setSelectedChapterList(data)
                        }} className={`${index % 2 != 0 ? "bg-input-gray" : ""} border-b`}>
                          <td className="p-[10px]">{index + 1}</td>
                          <td className="p-[10px] ">{item.chapter_name}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <button type="button" onClick={(e) => { e.preventDefault(); document.getElementById('firstsubmit').click() }} className='w-full my-[50px] bg-primary px-[15px] py-[10px] text-white rounded'>{submitLoading ? "Loading..." : selectedTempalte?.excel_image ? "Preview" : "Submit"}</button>
          </div>
      }

      {responsePopUp && <div className="w-screen h-screen bg-black fixed z-[7] top-0 left-0 bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-[30px] w-[50%] max-h-[80vh] overflow-scroll relative">
          <p className="font-bold text-xl text-center m-[10px]">Message Sent Status</p>
          {
            messageResponse.map((item) => (
              <div className="flex gap-3 justify-between w-full items-center">
                <p>{item.group_name}</p>
                {item.message ? <p className="text-green-600"> Message Sent </p> : <p className="text-red-600">Message Not Sent</p>}
              </div>
            ))
          }
          <button className='w-full mt-[25px] bg-primary px-[15px] py-[10px] text-white rounded' onClick={() => {
            setMessageRespone([]);
            setResponsePopUp(false);
            setSheetNameList([]);
            setSelectedSheetName(null);
          }}>close</button>

          <button className='w-full mt-[25px] bg-primary px-[15px] py-[10px] text-white rounded' onClick={() => exportToExcel(messageResponse, 'response')} >Export to Excel</button>

        </div>
      </div>}
    </div>
  );
}